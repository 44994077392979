<div class="container area">
  <mat-card style="z-index: 11111 !important;">
    <span class="full-width back">
      <mat-icon *ngIf="step == 3" (click)="backTo1()">keyboard_arrow_left</mat-icon>
    </span>
    <header>
      <img width="200px" src="assets/img/logo.jpg" />
      <!-- <h1>Kiosk</h1> -->
      <div style="margin-top: 20px;">
        <img src="assets/img/car.jpg" width="250px" />
      </div>
    </header>
    <mat-card-content>

      <form class="f-form" [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="step == 1">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Select State</mat-label>
          <mat-select placeholder="state" formControlName="state" (selectionChange)="selectState()">
            <mat-option *ngFor="let item of States" [value]="item.stateName">
              {{ item.stateName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
            !form.controls['state'].valid && form.controls['state'].touched
          ">
            State is required
          </mat-error>
        </mat-form-field>
        <div class="platecode">
          <mat-form-field class="full-width" appearance="outline">
            <input matInput maxlength="7" minlength="1" placeholder="Plate Text" formControlName="plate" />
          </mat-form-field>
        </div>
        <mat-error class="full-width" style="margin: 0px 0px 20px 0px;" *ngIf="
        !form.controls['plate'].valid && form.controls['plate'].touched
      ">
          Plate Text is required
        </mat-error>


        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Select Site</mat-label>
          <mat-select placeholder="site" formControlName="site">
            <mat-option *ngFor="let item of Sites" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
              !form.controls['site'].valid && form.controls['site'].touched
            ">
            Site is required
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!(form.valid) || !(form.valid)">
          Send
        </button>
      </form>
      <div class="pay" *ngIf="showPayButton && step == 2">
        <h1>Amount :<span style="color: #4e0080;">{{ amount | number: '.2-2' }} </span>AED</h1>
        <!-- <button type="button" mat-raised-button color="primary" (click)="Pay()">
          Pay
        </button> -->
      </div>
      <div class="succcess" *ngIf="step == 3">
        <div class="thanks">
          <h2>Payment Completed Successfully</h2>
        </div>
      </div>
      <div class="succcess" *ngIf="step == 4">
        <div class="thanks">
          <h2>Your bill is 0.00 AED. Thank you for using Limitless Parking Ticketless System.</h2>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <ul class="circles">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
</div>