import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { ApiHttpService } from '../core/service/api-http.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-kiosk-page',
  templateUrl: './kiosk-page.component.html',
  styleUrls: ['./kiosk-page.component.scss'],
})
export class KioskPageComponent implements OnInit {
  BillResponse: any = {};
  id: string = '';
  step = 1;
  amount: number = 0;
  Sites: any[] = [];
  Codes: any = '';
  States: State[] = [];
  url: string = 'https://staging.engine.parking.ldcs.ae/';
  showPayButton: boolean = false;
  disabled: boolean = true;
  dataSend: ResponceData = {
    arrival: '',
    activityId: '',
    userId: null,
    plate: '',
    state: '',
    site: ''
  };
  public form: FormGroup = new FormGroup({
    state: new FormControl('', { validators: Validators.required }),
    site: new FormControl('', { validators: Validators.required }),
    plate: new FormControl('', { validators: Validators.required }),
  });
  constructor( private api: ApiHttpService, private _formBuilder: FormBuilder, private router: ActivatedRoute) { }
  ngOnInit(): void {
    this.Sites.push(ApiHttpService.siteName);
    this.form.controls['site'].setValue(ApiHttpService.siteName);
    this.getStates();
    this.api.getObservable().subscribe((data: any) => {
      if (data.site) {
        this.Sites = [];
        this.Sites.push(data.site);
        this.form.controls['site'].setValue(data.site);

      }
    })

  }

 
  onSubmit() {
    this.showPayButton = true;
    let data = {
      plate: this.form.value.plate,
      state: this.form.value.state,
      site: this.form.value.site,
    };
    this.api
      .postRequest(data.plate, data.state)
      .subscribe((res: any) => {
        console.log(res.status);

        if (res.status === 200) {
          this.dataSend = res.body.data;
          console.log(res.body.data);
          let ArrayData: UrlData[] = [];
          let bills = res.body.data.bills;
          let isWhiteListed = res.body.data?.isWhitelisted;
          if (bills.length > 0 && !isWhiteListed) {
            (res.body.data.bills).map((element: any) => {
              let data = {
                "tenantId": ApiHttpService.Xtenent,
                "arrival": element.arrival,
                "departureAttempt": element.departureAttempt,
                "plate": element.plate,
                "state": element.state,
                "site": element.siteCode,
                "userId": element.userId,
                "activityId": element.activityId,
                "type": 0,
                method: ApiHttpService.onExit?"LINKONEXIT":"LINK",
                nested: element?.nested

              }
              ArrayData.push(data)
            });

            this.api.pay(ArrayData).subscribe(respose => {
              if (respose.data?.url) {
                console.log((respose.data?.url).toString());
                window.location.href = respose.data?.url
              } else {
                this.api.showSuccessCssStyles('Your payment is 0,Thanks for your using limitless parking services', 'ok', { duration: 40000 })
              }

            })
          } else if (isWhiteListed) {
            this.api.showSuccessCssStyles('You are WhiteListed. Your payment is 0, Thanks for your using limitless parking services', 'ok', { duration: 40000 })
          } else {
            this.api.showSuccessCssStyles('Your payment is 0, Thanks for your using limitless parking services', 'ok', { duration: 40000 })
          }
        }
      });
  }
  backTo1() {
    this.step = 1;
  }
  getSites() {
    this.api.getSites().subscribe((res) => {
      console.log(res);
      this.Sites = res.result.sites;
    });
  }
  getStates() {
    this.api.getStates().subscribe((res) => {
      console.log(res);
      this.States = []
      this.States = res?.body?.data?.states;
    });
  }
  ngOnChanges(): void {
    if (this.form.value.state) {
      let name = this.form.value.state;
      let states = this.States.slice();
      let currentState = states.find(
        (ele) => ele.stateName == name
      )?.plate_codes;
      this.Codes = currentState;
      console.log(currentState);
    }
  }
  selectState() {
    console.log(this.form.value.state);
    if (this.form.value.state) {
      this.form.patchValue({
        code: '',
        number: '',
      });
      this.disabled = false;
      let name = this.form.value.state;
      let states = this.States.slice();
      let currentState = states.find(
        (ele) => ele.stateName == name
      )?.plate_codes;
      this.Codes = currentState;
      console.log(currentState);
    }
  }

}
export interface Bill {
  publicId: string;
  publicBill: {
    publicId: string;
    amount: number;
  };
}
export interface State {
  stateName: string;
  plate_codes: [];
}
export interface ResponceData {
  arrival: string;
  activityId: string;
  userId: string | null;
  plate: string;
  state: string;
  site: string;
}

export interface UrlData {
  tenantId: string,
  arrival: string,
  departureAttempt: string,
  plate: string,
  state: string,
  site: string,
  userId: string,
  activityId: string,
  type: number,
  method: string,
  nested: nested[]
}
interface nested {
  "areaType": string,
  "timeSpent": number
}
/*

        "arrival": "2024-05-21T12:04:48.421Z",
        "departureAttempt": "2024-05-21T16:06:52.425Z",
        "activityId": "664cdc3321f835cc9355f463",
        "userId": "null",
        "plate": "E15165",
        "state": "Dubai",
        "site": "Tilal Al Ghaf - Retail Hub",
        "siteCode":"5HQiui_M4RsI",
        "nested": [{"areaType": "RESERVED", "timeSpent": 0}, {"areaType": "PUBLIC", "timeSpent": 0}, {"areaType": "VIP", "timeSpent": 0}, {"areaType": "GYM", "timeSpent": 0}]


*/
